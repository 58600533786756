import { SUB_VERSION_MIGRATION, SUB_EX_AGE } from '@/store/const/expertise'
import { copyObject } from '@/lib/objects'

const EX_AGENCIES_TEMPLATE_VERSION = 2

export const EX_AGENCIES_NICE = 0
export const EX_AGENCIES_IQWIG = 1
export const EX_AGENCIES_CADTH = 2
// агентства
export const EX_AGENCIES_DEFAULT = {
  data: [
    {
      name: 'NICE',
      data: [
        {
          original: '',
          translate: '',
          link: '',
        },
      ],
    },
    {
      name: 'IQWIG',
      data: [
        {
          original: '',
          translate: '',
          link: '',
        },
      ],
    },
    {
      name: 'CADTH',
      data: [
        {
          original: '',
          translate: '',
          link: '',
        },
      ],
    },
  ],
}

// Установили текущую версию данных
SUB_VERSION_MIGRATION[SUB_EX_AGE].v = EX_AGENCIES_TEMPLATE_VERSION
// Добавили миграции для перехода на версию 2
SUB_VERSION_MIGRATION[SUB_EX_AGE][1] = function (prev) {
  const data = copyObject(EX_AGENCIES_DEFAULT.data)
  data.forEach((agency, index) => {
    agency.data = prev[index]?.data ?? agency.data
  })
  return { data, v: 2 }
}
